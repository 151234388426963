<template>
  <div>
    <div class="level-item">
      <h1 class="title is-1 page-title">COVID-19 TRAVEL INSURANCE</h1>
    </div>
    <div class="box b-shdw-3">
      <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />

      <!-- Injected content -->
      <div class="content">
        <p>
          There are additional things we now need to consider with booking a
          holiday abroad, we just wanted to let you know that we are here for
          you when you are ready to travel again. We have adapted our policies
          to cover certain types of
          <span class="orange">disruption caused by Covid-19</span>, so when you
          decide to book your next trip, you can now have peace of mind that
          your holiday is protected with our,
          <span class="orange">Covid-19 cover for cancellation</span>
          and <span class="orange">emergency medical treatment</span>.
        </p>

        <p>
          All of our policies now include Covid-19 cover. 
          We provide cover for cancellation of your trip if you or anyone named on 
          your policy tests positive for Covid-19. 
          Covid-19 has always been and remains covered for emergency medical expenses abroad.
        </p>

        <p>
          Covered2go customers will also benefit from
          <span class="orange">self-isolation cover</span>. I.e.,
          Cancellation Cover if you need to quarantine as a result of mandatory confinement.
          <span class="orange">Denied Boarding</span> cover has also been
          extended to apply to <strong>outward journeys</strong>.
        </p>

        <div style="display:flex;justify-content:space-between;">
          <div>
            <h2>WHAT DOES OUR COVID-19 COVER INCLUDE:</h2>

            <ul>
              <li>Our Platinum, Gold and Silver products now include Covid-19 Cover</li>
              <li>
                <strong>Cover for testing positive</strong> - Cover if you need to cancel your
                holiday due to you or anyone named on your policy testing positive
                for <strong>Covid-19</strong>.
              </li>
              <li>
                <strong>Quarantine (before you leave) </strong> - Cover if you have to quarantine at home before your trip due to Covid-19 by order or other requirement of a government or public authority.
              </li>
              <li>
                <strong>Quarantine (whilst you're away)</strong> - Cover if you have to quarantine whilst you are away, due to contracting Covid-19.
              </li>
              <li>
                <strong>Medical Expenses</strong> - Cover for medical expenses and repatriation due
                to developing <strong>Covid-19</strong> whilst abroad*.
              </li>
              <li>
                <strong>Refused Boarding Cover</strong> - Cover if you are refused boarding by an airline, as
                a result of contracting <strong>Covid-19*</strong>.
              </li>
              <li>
                <strong>Pre-Booked Excursions</strong> – Cover for excursions or events that cannot
                be recovered from any other source.
              </li>
              <li>
                <strong>Redundancy</strong> - Cover if you need to cancel your trip as a result of
                redundancy.
              </li>
              <li>
                <strong>End Supplier Insolvency Insurance</strong> – Cover for Financial Failure of your; scheduled airline, hotel, train operator including Eurostar, car ferries; cruise companies; villas abroad & cottages in the UK; coach operator, car or camper hire company, caravan sites, campsites, mobile home, safaris; excursions; Eurotunnel; theme parks or attractions all known as the End Supplier of the travel arrangements not forming part of an inclusive holiday prior to departure.
              </li>

            </ul>

            <p>
              *Please note, this only applies to you or anyone named on your Policy.
            </p>

            <p>
              Terms and Conditions Apply. Please see our
              <a href="https://www.covered2go.co.uk/policy-wordings-mul"
                ><strong>Policy Wording</strong></a
              >
              for full details.
            </p>

            <p>
              If you do wish to travel to a country
              <strong>against the advice of the FCDO</strong>, you may require a
              specialist travel insurance provider. Covered2go have partnered with
              <a
                href="https://www.battleface.com/en-gb/?partner=194"
                target="_blank"
                ><strong>battleface</strong></a
              >, a provider of flexible insurance plans for travellers going to
              unconventional locations,
              <strong
                >including those under FCDO "essential only" travel
                advisories.</strong
              >
            </p>

            <p>
              For more information and to obtain a
              <a
                href="https://www.battleface.com/en-gb/?partner=194"
                target="_blank"
                ><strong>battleface</strong></a
              >
              quote please
              <a
                href="https://www.battleface.com/en-gb/?partner=194"
                target="_blank"
                ><strong>click here</strong></a
              >.
            </p>

            <p class="small">
              battleface insurance is provided in the UK by battleface Insurance Services Limited, a UK insurance intermediary
              authorised and regulated by the Financial Conduct Authority. Details provided on this webpage are for information
              only. Terms, conditions, exclusions, and limitations apply. Always read the description of cover contained within
              your policy to ensure it is suitable for your needs.
            </p>
          </div>
          <div style="width:500px; display: flex; flex-direction:column;flex-wrap:wrap;align-items:center;justify-content:flex-start;margin-top:40px;padding:5px;">
            <img src="/img/defaqto.png" />
            <div id="trustbox" style="margin-top:30px;" class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="50b7a9eb00006400051f090e" data-style-height="40px" data-style-width="100%" data-theme="light">
              <a href="https://uk.trustpilot.com/review/covered2go.co.uk" target="_blank" rel="noopener">Trustpilot</a>
            </div>
            <img src="/img/superior-rated-badge.png" />
          </div>
        </div>
      </div>

      <table class="covid-table">
        <thead>
          <tr>
            <th colspan="3" class="orange" style="text-align:center;">
              Please Remember - There is no cover on any of our policies if you travel against government or other
              regulatory authority (i.e., FCDO) or medical advice
            </th>
          </tr>
          <tr>
            <th></th>
            <th class="top-header center">You will be covered in the event that</th>
            <th class="top-header center">
              Platinum, Gold and Silver (Single Trip and Annual Multi-Trip)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="5" class="left-header-bb-0 vertical-center">
              Before You Leave
              <br />
              <strong>Cancellation Cover</strong>
            </td>
            <td class="center">You test positive for Covid-19 and are unable to travel.</td>
            <td class="center">
              ✓
            </td>
          </tr>
          <tr>
            <td class="center">
              You have been made redundant and cannot afford to travel anymore.
            </td>
            <td class="center">✓</td>
          </tr>
          <tr>
            <td class="center">
              You have to quarantine at home due to Covid-19 by order or other requirement of a government or
              public authority.
            </td>
            <td class="center">✓</td>
          </tr>
          <tr>
            <td class="center">
              You have to quarantine at home due to Covid-19 by order or other requirement of a government or public
              authority.
            </td>
            <td class="center">✓</td>
          </tr>
          <tr>
            <td class="center">
              You or a family member were hospitalised or died due to Covid-19.
            </td>
            <td class="center">✓</td>
          </tr>

          <tr>
            <td class="left-header-bb-0 vertical-center">
              Before You Leave
              <br />
              <strong>Denial of Boarding</strong>
            </td>
            <td class="center">
              You were refused boarding by your transport provider on the outward journey of your trip due having, or
              being suspected of having Covid-19
            </td>
            <td class="center">✓</td>
          </tr>
          <tr>
            <td rowspan="2" class="left-header-bb-0 vertical-center">
              Once you have left home
              <br />
              <strong>Emergency Medical Assistance Cover</strong>
            </td>
            <td class="center">
              You fall ill with Covid-19 (or other Pandemic illness) abroad and need medical treatment abroad.
            </td>
            <td class="center">✓</td>
          </tr>
          <tr>
            <td class="center">
              You fall ill with Covid-19 (or other Pandemic illness) abroad and need to be repatriated to the UK.
            </td>
            <td class="center">✓</td>
          </tr>
          <tr>
            <td rowspan="2" class="left-header-bb-0 vertical-center">
              Once you have left home<br />
              <strong>Curtailment Cover</strong>
            </td>
            <td class="center">
              You or a family member were hospitalised or died due to Covid-19, for the portion of travel and
              accommodation expenses which are not used.
            </td>
            <td class="center">
              ✓
            </td>
          </tr>
          <tr>
            <td class="center">
              Additional travelling expenses for returning home earlier than planned.
            </td>
            <td class="center">
              ✓
            </td>
          </tr>

          <tr>
            <td class="left-header-bb-0 vertical-center">
              Once you have left home<br />
              <strong>Denial of Boarding Cover</strong>
            </td>
            <td class="center">
              You were refused boarding by your transport provider on the return journey of your trip due having, or
              being suspected of having Covid-19
            </td>
            <td class="center">
              ✓
            </td>
          </tr>

          <tr>
            <td colspan="3" class="orange" style="text-align:center; ">
              Please note: <strong>Unless otherwise stated in the table above, no other costs related to
              Covid-19 will be covered.</strong>
              <br /><br />
              Traffic Light System: <strong>There is no cover provided if you choose to cancel your trip due
              to your holiday destination being on the Red / Amber List. The cost of the quarantine or any loss of
              earnings is not covered on the travel insurance.</strong>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- FAQs -->
      <!--<div v-for="(s, k) in sections" :key="s.id + 'sad'">
        <p class="title is-4 s-header" :id="k">{{ k }}</p>-->

        <!-- Each question -->
        <!--<div v-for="i in s" :key="i + 'sct'">-->
          <!-- Card -->
          <!--<b-collapse class="card" :open="false">-->
            <!-- Card Header -->
            <!--<div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
            >
              <p class="card-header-title" v-html="content[`faq${i}_title`]" />
              <a class="card-header-icon">
                READ MORE...
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'" />
              </a>
            </div>-->
            <!-- Card body -->
            <!--<div class="card-content">
              <div v-html="content[`faq${i}_content`]" />
            </div>
          </b-collapse>
          <br />
        </div>
      </div>-->

      <!-- Bottom buttons -->
      <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />

      <br /><br />
      <info-boxes />
      <trustpilot-banner />
    </div>
  </div>
</template>

<script>
import InfoBoxes from '../components/common/InfoBoxes';
import InfoButtonOptions from '@/components/common/InfoButtonOptions';
import { contentAPI } from '@/mixins/content-api';
import { page } from '@/mixins/page';

export default {
  name: 'Covid19',
  data() {
    return {
      sections: {
        'Covid-19 Travel Insurance FAQs (Policies Issued On or After GO LIVE DATE)' : [ 18 ],
        'Covid-19 Travel Insurance FAQs (Policies Issued On or After 01 June 2021)' : [ 1, 2],
        'Covid-19 Travel Insurance FAQs (Policies Issued Before 29 August 2020)': [ 3, 5 ],
        'General Covid-19 Travel Insurance FAQs (Policies Issued On Or After 29 August 2020 but Before 01 June 2021)': [ 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
      },
    };
  },
  mixins: [contentAPI, page],
  components: {
    InfoBoxes,
    InfoButtonOptions,
  },
  methods: {
    loadTrustpilot() {
        const trustbox = document.getElementById('trustbox');
        window.Trustpilot.loadFromElement(trustbox);
    }
  },
  mounted() {
      this.loadTrustpilot();
  }
};
</script>

<style lang="scss" scoped>
@import 'src/styles/views/info-page';

.s-header {
  color: $c2g_orange;
  margin-bottom: 20px;
  margin-top: 20px;
}

.small {
  font-size: 12px;
}

.orange {
  color: $c2g_orange;
  font-weight: bold;
}

.covid-table {
  border-collapse: collapse;
  margin-bottom: 20px;

  th,
  td {
    border: 1px solid black;
    padding: 10px;
  }
  .top-header {
    color: $c2g_orange;
    background-color: rgba(128, 128, 128, 0.082);
  }
  .left-header-bb-0 {
    color: $c2g_orange;
    border-bottom: 0px;
    font-weight: bold;
    background-color: rgba(128, 128, 128, 0.082);
  }
  .left-header-bt-0 {
    color: $c2g_orange;
    border-top: 0px;
    font-weight: bold;
    background-color: rgba(128, 128, 128, 0.082);
  }

  .vertical-center {
    vertical-align: middle;
  }

  .center {
    text-align: center;
    vertical-align: middle;
  }

}
</style>
